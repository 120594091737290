<template>
  <div class="relativeProducts">
    <swiper
      :slidesPerView="2.2"
      :spaceBetween="15"
      class="relativeProductSwiper"
      :navigation="{
        nextEl: '#js-next1',
        prevEl: '#js-prev1'
      }"
      :breakpoints="{
        992: {
          slidesPerView: 4
        }
      }"
    >
      <swiper-slide v-for="item in products" :key="item.Id">
        <div class="card card-hover border-0">
          <router-link :to="`/product/${item.Id}`" class="text-dark">
            <img
              :src="item.Image"
              class="card-img-top"
              :alt="item.Name"
            />
            <div class="card-body px-0 py-0 pt-3">
              <h5 class="card-title title-height fw-light fs-10">
                {{item.Name}}
              </h5>
              <del class="card-text text-secondary fs-10 d-inline d-md-block d-lg-inline">NT${{$currency.currency(item.MSRP)}}</del>
              <p class="d-xl-inline text-danger ms-xl-2 fs-10">
                NT$ <span class="fs-5 fs-lg-4">{{$currency.currency(item.Price)}}</span>
              </p>
            </div>
          </router-link>
        </div>
      </swiper-slide>
    </swiper>
    <!-- navigation buttons -->
    <div id="js-prev1" class="swiper-button-prev"></div>
    <div id="js-next1" class="swiper-button-next"></div>
  </div>
</template>

<script>
import ProductSvc from '@/service/product-service.js'
export default {
  data () {
    return {
      products: []
    }
  },
  methods: {
    getHotProduct () {
      ProductSvc.GetHotProduct()
        .then((SvcData) => {
          this.products = SvcData
        })
    },
    getUnionCarRentalAddOn () {
      ProductSvc.GetUnionCarRentalAddOnList()
        .then((SvcData) => {
          this.products = SvcData
        })
    }
  },
  mounted () {
    const storedData = sessionStorage.getItem('store')
    if (storedData) {
      this.getUnionCarRentalAddOn()
    } else {
      this.getHotProduct()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 相關推薦 - 將 swiper navigation 箭頭放置外側 */
.relativeProducts{
  width: 100%;
  @media (min-width: 992px) {
    margin-left: auto;
    margin-right: auto;
    width: 96%;
    padding-left: 3.5em;
    padding-right: 3.5em;
    position: relative;
  }
}

.title-height{
  height: 4.8rem;
  overflow: hidden;
  line-height: 1.5;
}

::v-deep(.relativeProducts .swiper-button-next){
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 0;
  height: calc(100% - 3em);
}

::v-deep(.relativeProducts .swiper-button-prev){
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 0;
  height: calc(100% - 3em);
}

/* 相關推薦 - swiper navigation button 在 768px 以下隱藏 */
::v-deep(.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after){
  display: none;
  @media (min-width: 992px) {
    display: flex;
    color: #808080;
    font-size: 36px;
  }
}

::v-deep(.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after){
  display: none;
  @media (min-width: 992px) {
    display: flex;
    color: #808080;
    font-size: 36px;
  }
}
</style>
